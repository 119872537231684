import { graphql, useStaticQuery } from "gatsby"

function useNavigation() {
  const {
    site: {
      siteMetadata: { navigation },
    },
  } = useStaticQuery(graphql`
    query NavigationLinks {
      site {
        siteMetadata {
          navigation {
            blog
            about
          }
        }
      }
    }
  `)

  return navigation
}

export default useNavigation
