import React from "react"
import { Col, Container, Row } from "./grid"
import useNavigation from "../hooks/useNavigation"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import Link from "./link"
import { useLang } from "../hooks/useLang"
import useSocials from "../hooks/useSocials"
import { FaIcon } from "./icon"
import { useTheme } from "emotion-theming"
import { iconBtn } from "./button"
import Newsletter from "./newsletter"
import mq from "./layout/mq"

const FooterHeader = styled.h5`
  font-weight: 700;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 25px;
`

const FooterLink = styled(Link)`
  font-size: 16px;
  line-height: 36px;
  color: ${p => p.theme.colors.regular};
  position: relative;
  display: inline-block;

  &:hover {
    color: ${p => p.theme.colors.accent};
    text-decoration: none;
  }

  &:hover::before {
    width: 100%;
  }

  &:before {
    background-color: ${p => p.theme.colors.accent};
    content: "";
    display: block;
    position: absolute;
    height: 3px;
    width: 0;
    bottom: 0;
    transition: all 0.4s cubic-bezier(0.22, 0.68, 0, 1.71);
  }
`

const trans = {
  pl: {
    links: "Linki",
    blog: "Blog",
    about: "O mnie",
    contact: "Napisz do mnie",
    form: "Formularz kontaktowy",
    subscribe: "Zapisz się na newsletter",
  },
  en: {
    links: "Quick Links",
    blog: "Blog",
    about: "About me",
    contact: "Contact Me",
    form: "Contact form",
    subscribe: "Subscribe To My Mailing List",
  },
}

const FooterLinks = () => {
  const links = useNavigation()
  const lang = useLang()

  return (
    <div
      css={css`
        ul li {
          list-style: none;
        }
      `}
    >
      <FooterHeader>{trans[lang].links}</FooterHeader>
      <ul>
        {Object.entries(links).map(([key, path]) => (
          <li key={key}>
            <FooterLink to={path}>{trans[lang][key]}</FooterLink>
          </li>
        ))}
      </ul>
    </div>
  )
}

const Contact = () => {
  const lang = useLang()
  const socials = useSocials()
  const theme = useTheme()

  return (
    <div
      css={css`
        margin-bottom: 20px;
      `}
    >
      <FooterHeader>{trans[lang].contact}</FooterHeader>
      <div
        css={css`
          margin-bottom: 20px;
          a {
            color: ${theme.colors.accent};
          }
        `}
      >
        <Link to="/about/#contact">{trans[lang].form}</Link>
      </div>
      <div>
        {Object.entries(socials).map(([name, url]) => (
          <a
            css={iconBtn(theme)}
            key={name}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaIcon icon={["fab", name]} />
          </a>
        ))}
      </div>
    </div>
  )
}

const Footer = () => {
  const theme = useTheme()
  const lang = useLang()

  return (
    <footer
      css={css`
        margin-top: 70px;
        padding-bottom: 30px;
      `}
    >
      <Container>
        <p
          css={css`
            font-size: 14px;
            color: ${theme.colors.disable};
            line-height: 20px;
            text-align: center;
            margin-top: 10px;
          `}
        >
          Copyright ©{new Date().getFullYear()} przemuh.dev
        </p>
      </Container>
    </footer>
  )
}
export default Footer
