import React from "react"
import { Global, css } from "@emotion/core"
import { ThemeProvider } from "emotion-theming"

import "./custom_bootstrap.css"
import "./typography.css"
import theme from "./theme"
import { global } from "./styles"

import { LangProvider } from "../../context/lang"

import Header from "../header"
import Footer from "../footer"
import mq from "./mq"

const Layout = ({ children, title, langKey = "pl" }) => {
  return (
    <LangProvider value={langKey}>
      <ThemeProvider theme={theme}>
        <Global styles={global} />
        <Header title={title} />
        <div
          css={css`
            padding-top: 80px;
            margin-bottom: 30px;

            ${mq.desktop} {
              padding-top: 125px;
            }
          `}
        >
          {children}
        </div>
        <Footer />
      </ThemeProvider>
    </LangProvider>
  )
}

export default Layout
