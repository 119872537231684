import { css } from "@emotion/core"

export const global = theme => css`
  body {
    font-family: "Cerebri", sans-serif;
    color: ${theme.colors.regular};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.colors.title};
  }
`
export const link = theme => css`
  color: ${theme.colors.title};
  transition: 0.3s ease;

  &:hover,
  &:focus {
    outline: none;
    color: ${theme.colors.accent};
  }
`
