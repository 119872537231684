export default {
  colors: {
    title: "#1d2c44",
    regular: "#646d7c",
    accent: "#f17070",
    disable: "#a4a8b0",
    border: "#ebebeb",
  },
  bg: {
    white: "#fff",
  },
}
