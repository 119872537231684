import React, { useEffect, useState } from "react"
import debounce from "lodash/debounce"
import { css } from "@emotion/core"

import useSocials from "../hooks/useSocials"

import Link from "./link"
import { Col, Container, Row } from "./grid"
import { FaIcon } from "./icon"

import {
  wrapperStyles,
  Overlay,
  HeaderWrapper,
  Logo,
  Hamburger,
  Socials,
  Navigation,
} from "./header.styles"
import { useLang } from "../hooks/useLang"
import LangSwitcher from "./langSwitcher"
import useNavigation from "../hooks/useNavigation"
import mq from "./layout/mq"

const SCROLL_THRESHOLD = 100

const t = {
  en: {
    blog: "Blog",
    about: "About me",
  },
  pl: {
    blog: "Blog",
    about: "O mnie",
  },
}

const Header = ({ title }) => {
  const [isMenuOpened, setMenuOpened] = useState(false)
  const [isScrolled, setScrolled] = useState(false)
  const socials = useSocials()
  const lang = useLang()
  const links = useNavigation()

  useEffect(() => {
    const onScroll = debounce(() => {
      const scrollPos = window.scrollY
      if (
        (isScrolled && scrollPos < SCROLL_THRESHOLD) ||
        (!isScrolled && scrollPos >= SCROLL_THRESHOLD)
      ) {
        setScrolled(scrollPos >= 100)
      }
    })

    window.addEventListener("scroll", onScroll, false)

    return () => window.removeEventListener("scroll", onScroll)
  }, [isScrolled])

  return (
    <header css={wrapperStyles} key="header">
      {isMenuOpened && <Overlay onClick={() => setMenuOpened(false)} />}
      <HeaderWrapper shrink={isScrolled}>
        <Container>
          <Row noGutters className="align-items-center justify-content-center">
            <Col xs={3} md={2}>
              <Logo to="/">
                przemuh<i>.</i>dev
              </Logo>
            </Col>
            <Col xs={7}>
              <Navigation
                isMenuOpened={isMenuOpened}
                isScrolled={title && isScrolled}
              >
                <ul>
                  {Object.entries(links).map(([name, to], index) => (
                    <li key={name}>
                      <Link
                        className="link"
                        activeClassName="active"
                        to={to}
                        onClick={() => setMenuOpened(false)}
                      >
                        {t[lang][name]}
                      </Link>
                    </li>
                  ))}
                </ul>
                {title && (
                  <span
                    css={css`
                      display: none;
                      ${mq.desktop} {
                        display: block;
                      }
                    `}
                  >
                    {title}
                  </span>
                )}
                <LangSwitcher
                  css={css`
                    display: block;

                    ${mq.desktop} {
                      display: none;
                    }
                  `}
                />
              </Navigation>
            </Col>
            <Col
              xs={0}
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <Hamburger>
                <button onClick={() => setMenuOpened(prev => !prev)}>
                  <FaIcon icon="bars" />
                </button>
              </Hamburger>
              <Socials>
                {Object.entries(socials).map(([name, link]) => (
                  <a
                    key={name}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaIcon icon={["fab", name]} />
                  </a>
                ))}
                <a href={lang === "pl" ? "/rss" : "/en/rss"}>
                  <FaIcon icon="rss" />
                </a>
              </Socials>
              <LangSwitcher
                css={css`
                  display: none;

                  ${mq.desktop} {
                    display: block;
                  }
                `}
              />
            </Col>
          </Row>
        </Container>
      </HeaderWrapper>
    </header>
  )
}

export default Header
