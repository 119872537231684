import { css } from "@emotion/core"
import styled from "@emotion/styled"
import Link from "./link"
import { mq, styles } from "./layout"

export const wrapperStyles = css`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100vw;
  transition: 0.4s ease;
`

export const HeaderWrapper = styled.div`
  box-shadow: 0 4px 18px 0 rgba(32, 45, 73, 0.1);
  background-color: ${props => props.theme.bg.white};
  padding: 10px 0;
  transition: 0.4s ease;
  max-height: 104px;
  overflow: hidden;
`
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.43);
  z-index: 25;
`

export const Logo = styled(Link)`
  display: inline-block;
  text-decoration: none;
  font-size: 20px;
  font-weight: 800;
  color: ${p => p.theme.colors.title};
  line-height: 20px;

  &:hover {
    color: ${p => p.theme.colors.title};
    text-decoration: none;
  }

  i {
    color: ${p => p.theme.colors.accent};
    font-size: 35px;
    line-height: 1px;
  }
`

export const Hamburger = styled.div`
  display: block;
  text-align: right;

  ${mq.desktop} {
    display: none;
  }

  button {
    border: none;
    background: none;
    font-size: 20px;
    line-height: 1;

    ${p => styles.link(p.theme)}
  }
`

export const Navigation = styled.nav`
  z-index: 35;
  box-shadow: 0 4px 18px 0 rgba(32, 45, 73, 0.1);
  position: fixed;
  top: 0;
  left: ${p => (p.isMenuOpened ? 0 : "-100%")};
  width: 80vw;
  height: 100vh;
  background-color: ${p => p.theme.bg.white};
  padding: 30px;
  overflow-y: auto;
  visibility: ${p => (p.isMenuOpened ? "visible" : "hidden")};
  transition: all 0.4s ease;

  ${mq.tablet} {
    width: 50vw;
  }

  ${mq.smallDesktop} {
    width: 50vw;
  }

  ul {
    text-align: left;
    li {
      list-style: none;
      display: block;
      margin-bottom: 20px;

      &:hover {
        .link:before {
          width: calc(100% - 50px);
        }
      }

      .link {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: ${p => p.theme.colors.title};
        text-transform: capitalize;
        text-decoration: none;
        position: relative;
        padding: 0 25px 20px 25px;
        display: inline-block;

        &:before {
          background-color: ${p => p.theme.colors.accent};
          content: "";
          display: block;
          position: absolute;
          height: 3px;
          width: 0;
          bottom: 10px;
          transition: all 0.4s cubic-bezier(0.22, 0.68, 0, 1.71);
        }

        &.active:before {
          width: calc(100% - 50px);
        }
      }
    }
  }

  ${mq.desktop} {
    position: initial;
    width: initial;
    height: 54px;
    padding: initial;
    box-shadow: none;
    visibility: visible;
    overflow: hidden;

    ul,
    span {
      ${p => (p.isScrolled ? `transform: translateY(-54px);` : "")};
      transition: all 0.4s ease;
    }

    span {
      display: inline-block;
      width: 100%;
      line-height: 54px;
      text-align: center;
      font-weight: 600;
      color: ${p => p.theme.colors.title};
      opacity: ${p => (p.isScrolled ? 1 : 0)};
    }

    ul {
      text-align: center;
      padding-left: 0;
      margin-bottom: 0;

      li {
        display: inline-block;
        margin-bottom: 0;

        .link {
          padding: 15px 25px;
        }
      }
    }
  }
`

export const Socials = styled.div`
  display: none;

  ${mq.desktop} {
    display: inline-block;
    overflow: visible;
  }

  a {
    ${p => styles.link(p.theme)};
    font-size: 16px;
    line-height: 1;
    margin: 0 10px;
    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
`
