import React, { useEffect, useRef } from "react"
import styled from "@emotion/styled"

let Plyr

if (typeof window !== `undefined`) {
  Plyr = require("plyr")
}

const Wrapper = styled.div`
  &:hover {
    .plyr {
      .plyr__controls {
        opacity: 1;
      }
    }
  }
`

const VideoPlayer = ({ title, id }) => {
  const player = useRef(null)
  const el = useRef(null)

  useEffect(() => {
    player.current = new Plyr(el.current)
    return () => player.current.destroy()
  }, [])

  return (
    <Wrapper className="player">
      <div className="plyr__video-embed" ref={el}>
        <iframe
          title={title}
          src={`https://www.youtube.com/embed/${id}?rel=0&showinfo=0`}
          allowFullScreen
          allowTransparency
          allow="autoplay"
        />
      </div>
    </Wrapper>
  )
}

export default VideoPlayer
