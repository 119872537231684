import React from "react"
import styled from "@emotion/styled"
import { Location } from "@reach/router"
import { Link } from "gatsby"
import { useLang } from "../hooks/useLang"

const Wrapper = styled.div`
  display: block;
  margin-left: 20px;

  a {
    text-decoration: none;
    display: inline-block;
    font-size: 1.5em;
  }

  a + a {
    margin-left: 0.5em;
  }
`

const Icon = styled(Link)`
  filter: grayscale(90%);

  &:hover,
  &[data-active="true"] {
    filter: none;
  }
`

const LangSwitcher = props => {
  const currentLang = useLang()

  return (
    <Location>
      {({ location: { pathname } }) => {
        const langFreePath =
          currentLang === "pl" ? pathname : pathname.substr(3)

        return (
          <Wrapper {...props}>
            <Icon
              to={langFreePath}
              replace={true}
              data-active={currentLang === "pl"}
            >
              <span role="img" aria-label="polish">
                🇵🇱
              </span>
            </Icon>
            <Icon
              to={`/en${langFreePath}`}
              replace={true}
              data-active={currentLang !== "pl"}
            >
              <span role="img" aria-label="english">
                🇬🇧
              </span>
            </Icon>
          </Wrapper>
        )
      }}
    </Location>
  )
}

export default LangSwitcher
