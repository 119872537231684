import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { useTheme } from "emotion-theming"
import { css } from "@emotion/core"

import { link } from "../layout/styles"
import { useLang } from "../../hooks/useLang"

export const PostImg = styled.div`
  width: 100%;
  overflow: hidden;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`

export const PostTitle = styled(Link)`
  ${p => link(p.theme)};

  display: inline-block;
  font-weight: 700;

  font-size: 36px;
  line-height: 44px;

  &.regular {
    font-size: 26px;
    line-height: 30px;
  }
`

export const PostCredit = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  white-space: nowrap;
`

export const PublicationDate = styled.h5`
  font-weight: 400;
  line-height: 1;
  font-size: 14px;
  color: ${p => p.theme.colors.disable};
  text-transform: uppercase;
  margin-right: 30px;
`

export const ReadMore = ({ to }) => {
  const lang = useLang()
  const theme = useTheme()

  const text = lang === "pl" ? "Czytaj dalej" : "Read more"

  return (
    <Link
      css={css`
        color: ${theme.colors.accent}!important;
        font-size: 17px;
        line-height: 26px;
        margin-top: 10px;
        display: inline-block;
      `}
      to={to}
    >
      {text}
    </Link>
  )
}

export const PostDescribe = styled.p`
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
`

export const PostVideo = styled.div`
  .plyr {
    min-width: auto;

    .plyr__controls {
      opacity: 0;
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.42));

      .plyr__control[role="menuitemradio"][aria-checked="true"]::before {
        background-color: ${p => p.theme.colors.accent};
      }

      .plyr__control {
        &[aria-expanded="true"] {
          background-color: ${p => p.theme.colors.accent};
        }
        &:hover {
          background-color: ${p => p.theme.colors.accent};
        }
        &.plyr__tab-focus {
          background: ${p => p.theme.colors.accent};
          &:hover {
            background-color: ${p => p.theme.colors.accent};
          }
        }
      }
    }
    .plyr__poster {
      background-size: cover;
    }
    .plyr__control--overlaid {
      background-color: #fff;
      color: #000;
      font-size: 16px;
      border-radius: 999px;
      padding: 20px;
      text-transform: uppercase;
      border: 0 solid ${p => p.theme.colors.accent};
      transition: 0.3s ease;
      display: inline-block;
      &:hover {
        background-color: ${p => p.theme.colors.accent};
        color: #fff;
        border: 0 solid ${p => p.theme.colors.accent};
        text-decoration: none;
      }
    }
  }
  .plyr--full-ui input[type="range"] {
    color: ${p => p.theme.colors.accent};
  }
`
