import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { DiscussionEmbed } from "disqus-react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "../components/grid"
import { Credit, CreditInner, Title, Image } from "../components/post/details"
import VideoPlayer from "../components/videoPlayer"
import { PostVideo } from "../components/post/elements"

const PostBlock = styled.div`
  font-size: 18px;
  color: ${p => p.theme.colors.regular};
  line-height: 30px;
  margin-bottom: 30px;
`

export const Content = styled.div`
  .gatsby-resp-image-figcaption {
    text-align: center;
    color: ${p => p.theme.colors.title};
    font-size: 0.9em;
    font-style: italic;
    font-weight: 300;
  }

  .gatsby-highlight {
    margin-bottom: 20px;
    font-size: 0.8em;
    position: relative;

    pre[class^="language-"]::before {
      font-weight: 300;
      display: inline-block;
      min-width: 40px;
      min-height: 35px;
      text-align: center;
      position: absolute;
      right: 10px;
      top: 0;
      color: #000;
      padding: 5px;
      line-height: 1.5;
      border-radius: 0 0 20px 20px;
    }

    pre[class="language-javascript"]::before {
      content: "js";
      background: rgb(247, 233, 30);
    }

    pre[class="language-css"]::before {
      content: "css";
      background: #2965f1;
    }

    pre[class="language-bash"]::before {
      content: "bash";
      background: ${p => p.theme.colors.accent};
    }

    pre[class="language-json"]::before {
      content: "json";
      background: #209555;
    }
  }

  h2 {
    margin-top: 40px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 20px;
    color: ${p => p.theme.colors.title};
    font-weight: 700;
  }

  img {
    width: 100%;
    margin-bottom: 50px;
  }

  p {
    margin-bottom: 20px;
  }

  blockquote {
    margin-bottom: 40px;
    p {
      font-weight: 400;
      font-style: italic;
      font-size: 18px;
      color: ${p => p.theme.colors.title};
      line-height: 30px;
      display: inline-block;
      margin-bottom: 15px;

      &::before {
        content: open-quote;
      }

      &::after {
        content: close-quote;
      }
    }
    .author {
      font-weight: 400;
      font-size: 18px;
      color: ${p => p.theme.colors.accent};
      line-height: 1;
      text-align: right;
    }
  }

  ul,
  ol {
    padding-left: 2rem;
  }

  strong {
    color: ${p => p.theme.colors.title};
    font-weight: 700;
    display: inline;
  }

  p > code {
    background: transparent;
    color: ${p => p.theme.colors.accent};
    font-size: 0.9em;
  }
`

export default function PageTemplate({
  pageContext: { langKey, slug },
  data: {
    mdx,
    mdx: { frontmatter },
  },
}) {
  const imageFluid =
    frontmatter.image && frontmatter.image.childImageSharp.fluid

  const generalSlug = slug.startsWith("/en") ? slug.slice(3) : slug

  return (
    <Layout langKey={langKey} title={frontmatter.title}>
      <SEO title={frontmatter.title} lang={langKey} imageSrc={imageFluid.src} />
      <MDXProvider>
        <section>
          <Container>
            <Row className="row justify-content-center">
              <Col xs={12} lg={10}>
                <PostBlock>
                  <Title>{frontmatter.title}</Title>
                  <Credit>
                    <CreditInner>
                      {/*<h5>tag</h5>*/}
                      <p>
                        {new Date(frontmatter.date).toLocaleDateString(
                          langKey,
                          {
                            dateStyle: "long",
                          }
                        )}
                      </p>
                    </CreditInner>
                  </Credit>

                  {imageFluid && (
                    <Image className="mg-fluid" fluid={imageFluid} />
                  )}

                  {frontmatter.video && (
                    <PostVideo
                      css={css`
                        margin-bottom: 50px;

                        .plyr {
                          border-radius: 5px;
                        }
                      `}
                    >
                      <VideoPlayer
                        {...frontmatter.video}
                        title={frontmatter.title}
                      />
                    </PostVideo>
                  )}

                  <Content>
                    <MDXRenderer>{mdx.body}</MDXRenderer>
                    <DiscussionEmbed
                      shortname="przemuh-dev"
                      config={{
                        url: `https://przemuh.dev${generalSlug}`,
                        identifier: generalSlug,
                        title: frontmatter.title,
                        language: langKey, //e.g. for Traditional Chinese (Taiwan)
                      }}
                    />
                  </Content>
                </PostBlock>
              </Col>
            </Row>
          </Container>
        </section>
      </MDXProvider>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($slug: String, $langKey: String) {
    mdx(fields: { langKey: { eq: $langKey }, slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        date
        image {
          childImageSharp {
            fluid(maxWidth: 1170) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
