import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { FaIcon } from "./icon"
import { useTheme } from "emotion-theming"
import { input } from "./input"
import Link from "./link"
import { useLang } from "../hooks/useLang"

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 10px;
  button {
    position: absolute;
    top: 50%;
    right: 10px;
    border: 0;
    background-color: transparent;
    transform: translateY(-50%);
    display: inline-block;
    font-size: 16px;
    color: ${p => p.theme.colors.regular};
    line-height: 36px;

    &:hover {
      color: ${p => p.theme.colors.accent};
    }
  }
`

const trans = {
  pl: {
    agreement: "Przeczytałem i zgadzam się z",
    tos: "Polityką Prywatności",
    email: "Podaj swój email",
  },
  en: {
    agreement: "I've read and accept the",
    tos: "Privacy Policy",
    email: "Enter Your Mail",
  },
}

const Newsletter = () => {
  const theme = useTheme()
  const lang = useLang()

  return (
    <form
      action="https://dev.us4.list-manage.com/subscribe/post?u=30aa3790f7406bd7494c0d79d&amp;id=5b7e741a2d"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
    >
      <Wrapper>
        <input
          css={input(theme, "45px")}
          type="email"
          placeholder={trans[lang].email}
          name="EMAIL"
          id="mce-EMAIL"
        />
        <button
          type="submit"
          value="Subscribe"
          name="subscribe"
          id="mc-embedded-subscribe"
        >
          <FaIcon icon={["fas", "paper-plane"]} />
        </button>
      </Wrapper>
      <div>
        <input type="checkbox" id="read" required />
        <label
          htmlFor="read"
          css={css`
            font-size: 14px;
            color: ${theme.colors.disable};
            display: inline-block;
            white-space: pre;
            cursor: pointer;
            a {
              color: ${theme.colors.accent};
            }
          `}
        >
          {" "}
          {trans[lang].agreement} <Link to="/tos">{trans[lang].tos}</Link>
        </label>
      </div>
    </form>
  )
}

export default Newsletter
